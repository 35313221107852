import React, { Component } from 'react'
import * as styles from './style.module.css'
import {graphql, Link, StaticQuery} from 'gatsby'
import {
	CarouselProvider,
	Dot,
	Slide,
	Slider,
	Image
} from "pure-react-carousel"
import 'pure-react-carousel/dist/react-carousel.es.css'

const sliderlist = {
        "desktop-slider1.jpg": {
                            "title":"Harmful insects live among us <span class=\"titleampersand\">&</span> carry diseases",
                            "button": "Know your Pests",
                            "buttonurl": "howitworks#knowyourpests"
                        },
        "mobile-slider1.jpg": {
                            "title":"Harmful insects live among us <span class=\"titleampersand\">&</span> carry diseases",
                            "button": "Know your Pests",
                            "buttonurl": "howitworks#knowyourpests"
                        },
        "desktop-slider2.jpg": {
                            "title":"How it works",
                            "subtitle": "See Bug Off in Action",
                            "button": "Watch Here",
                            "buttonurl": "howitworks"
                        },
        "mobile-slider2.jpg": {
                            "title":"How it works",
                            "subtitle": "See Bug Off in Action",
                            "button": "Watch Here",
                            "buttonurl": "howitworks"
                        },
        "desktop-slider3.jpg": {
                            "title":"Paint a safe future for your family",
                            "subtitle": "Tested and certified safe",
                            "button": "Learn More",
                            "buttonurl": "certified"
                        },
        "mobile-slider3.jpg": {
                            "title":"Paint a safe future for your family",
                            "subtitle": "Tested and certified safe",
                            "button": "Learn More",
                            "buttonurl": "certified"
                        },
        "desktop-slider4.jpg": {
                            "title":"Insect invaders don't stand a chance",
                            "button": "Learn More",
                            "buttonurl": "faq"
                        },
        "mobile-slider4.jpg": {
                            "title":"Insect invaders don't stand a chance",
                            "button": "Learn More",
                            "buttonurl": "faq"
                        },
        "desktop-slider5.jpg": {
                            "title":"Bug off! Life on!",
                            "button": "Contact Us Now",
                            "buttonurl": "contact"
                        },
        "mobile-slider5.jpg": {
                            "title":"Bug off! Life on!",
                            "button": "Contact Us Now",
                            "buttonurl": "contact"
                        }
    }

export default class HowToOrder extends Component {
	state = {
		width: 1024,
		height: 600,
		moving:false //for disabling automatic animation while user is navigating slideshow so that they won't double jump
	}

	componentDidMount() {
		this.handleResize()
		window.addEventListener('resize', this.handleResize)
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize)
	}

	handleResize = () => {
		this.setState(state => ({
			width: window.innerWidth,
			height: window.innerHeight
		}));
	};



	disableAnimation = () => {
		this.setState({moving : !this.state.moving})
		setTimeout( ( ()=>this.setState({moving : !this.state.moving}) ), 2000)
	}

    showSlideEntry = (entry) => {
        return (
            <Slide index={entry.node.id} >
                <Image src={entry.node.fluid.originalImg} isBgImage={true}  />
                {sliderlist[entry.node.fluid.originalName] && <div class={styles.slidecontent}>
                    { sliderlist[entry.node.fluid.originalName].title &&
                        <div class={`${styles.slidetitle} font-size-large font-style-title`} dangerouslySetInnerHTML={{__html:sliderlist[entry.node.fluid.originalName].title}}/>
                    }
                    { sliderlist[entry.node.fluid.originalName].subtitle &&
                        <div class={`${styles.slidesubtitle} font-size-medium`}>{sliderlist[entry.node.fluid.originalName].subtitle}</div>
                    }
                    { sliderlist[entry.node.fluid.originalName].button &&
                        <Link to={sliderlist[entry.node.fluid.originalName].buttonurl} className={`${styles.slidebutton} font-size-medium color-background-primary`}>
                            {sliderlist[entry.node.fluid.originalName].button}
                        </Link>
                    }

                </div>}
            </Slide>
        )
    }

	render() {
		const moving = this.state.moving

		const mobilewdhtratio = 1125/949
		const desktopwdhtratio = 2000/842
		const wdhtratio= (this.state.width/this.state.height)
        const cutoffwdhtratio = 1
		const imgwdhtratio= (wdhtratio>=cutoffwdhtratio)?desktopwdhtratio:mobilewdhtratio

        console.log()
        //const imagelist= (imgwdhtratio === cutoffwdhtratio ? this.props.data.desktop.edges : this.props.data.mobile.edges)
        // Use either desktop/mobile edges for length since they should be the same
		return (
            <>
                <StaticQuery
                        query={graphql`
                        query howtoOrderPhotos {
                            desktop: allImageSharp(
                                filter: {fluid: {originalName: {glob: "desktop-howtoorder-*"}}}
                                sort: { fields: [ fixed___originalName], order: ASC}
                            ){
                                edges {
                                    node {
                                        fluid {
                                            originalImg
                                            originalName
                                        }
                                        id
                                    }
                                }
                            }
                            mobile: allImageSharp(
                                filter: {fluid: {originalName: {glob: "mobile-howtoorder-*"}}}
                                sort: { fields: [ fixed___originalName], order: ASC}
                            ){
                                edges {
                                    node {
                                        fluid {
                                            originalImg
                                            originalName
                                        }
                                        id
                                    }
                                }
                            }
                        }
                    `}
                    render={data => (
                        <div className={wdhtratio>cutoffwdhtratio ? styles.slidewide:styles.slidetall }>
                            <CarouselProvider
                                hasMasterSpinner={true}
                                naturalSlideWidth={imgwdhtratio}
                                naturalSlideHeight={1}
                                totalSlides={data.desktop.edges.length}
                                className={styles.container}
                            >
                                <Slider
                                    className={styles.containerslide}
                                    classNameAnimation={styles.containerslideactive}
                                >
                                    {wdhtratio>cutoffwdhtratio ?
                                        data.desktop.edges.map(this.showSlideEntry)
                                        :
                                        data.mobile.edges.map(this.showSlideEntry)
                                        }
                                </Slider>
                                <div
                                    className={`${styles.dotGroup}`}
                                >
                                    {data.desktop.edges.map((data, index) =>
                                        <Dot slide={index}></Dot>
                                    )}
                                </div>
                            </CarouselProvider>
                        </div>
                    )}
                />
            </>
		)
	}
}
