// extracted by mini-css-extract-plugin
export var button = "style-module--button--2cab8";
export var buttonBack = "style-module--buttonBack--bb7f5";
export var buttonNext = "style-module--buttonNext--668e9";
export var buttonplaceholder = "style-module--buttonplaceholder--eac99";
export var container = "style-module--container--06735";
export var containerslide = "style-module--containerslide--91fe8";
export var containerslideactive = "style-module--containerslideactive--40c57";
export var dotGroup = "style-module--dotGroup--e4c18";
export var slidebutton = "style-module--slidebutton--d3f88";
export var slidecontent = "style-module--slidecontent--86ea0";
export var slidesubtitle = "style-module--slidesubtitle--e1306";
export var slidetall = "style-module--slidetall--78d7b";
export var slidetitle = "style-module--slidetitle--48c89";
export var slidewide = "style-module--slidewide--7848a";