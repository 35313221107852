import React, { Component } from 'react'
import {Link} from 'gatsby'
import {Container, Header, Loader, Icon, Grid} from 'semantic-ui-react'
import Seo from '../components/SEO'
import Layout from '../components/Layout'
import * as styles from './styles/pages.module.css'
import HowToOrder from '../components/HowToOrder'

const Negosyo = require('../../lib/negosyo')

export default class PickUpInfo extends Component {
	state = {
		pickupOptions:undefined,
		loadingPickup:true,
		errorLoadingPickup:false
	}

	componentDidMount() {

		Negosyo.getPickUpPoints()
		.then((addresses)=>{
			this.setState({pickupOptions:addresses, loadingPickup:false})
		}).catch((err)=>{
			this.setState({loadingPickup:false, errorLoadingPickup:true})
		})

	}

	pickUpOptionRender = (item) =>{
		return (
			<Grid.Row key={item.code}>
				<Grid.Column computer={15} tablet={14} mobile={13}>
					<li className={styles.listItem}>
						{item.name}:{item.address}
						<a
							href={`https://www.google.com/maps/search/NegosyoNow+${item.name.replace(/\s/g,'+')}/@${item.gps.latitude},${item.gps.longitude},18z`}
							target="_blank"
							rel="noopener noreferrer"
							title={`Link to google map location of ${item.name}`}
							className={styles.pickUpLink}
						>
							<Icon name="map marker alternate" size="regular" className={styles.mapicon}/>
						</a>
					</li>
				</Grid.Column>
			</Grid.Row>
		)
	}
	render() {
		const location = this.props.location
		const {
			loadingPickup,
			pickupOptions,
			errorLoadingPickup
		} = this.state
		const {
			pickUpOptionRender
		} = this
		return (
			<Layout location={location}>
				<Seo
					canonicalpath="/pick-up-transactions"
					title={"Pick Up Transactions"}
					description="Pick up your items from a store or bus terminal at your province. Book your preferred service to claim on your behalf. Try it now!"
				/>
				<Container className={styles.container}>
					<Header className={styles.superHeader}>Pick Up Transactions</Header>
					<p className={styles.text}>
						You may opt to claim orders from designated <Link to="/pick-up-transactions/#pick-up">Pick up points</Link> or selected <Link to="/pick-up-transactions/#bus-shipping">Bus Terminals</Link>.
						Below are the general procedures for each type of transaction:
					</p>
				</Container>
				<HowToOrder />
				<Container className={styles.container}>
					<span id="pick-up" className={styles.anchor}/>
					<Header className={styles.header}><h1>Pick Up Points</h1></Header>
					<ol className={styles.text}>
						<li className={styles.listItem}>
							<div className={styles.listItem}>
								<strong>Check out:</strong> You can choose from the following pick up points/show rooms upon checkout:
							</div>
							<div className={styles.text}>
								{errorLoadingPickup===false ? (
									<ul>
										{!loadingPickup ?  (
											<Grid >
												{pickupOptions.map(pickUpOptionRender)}
											</Grid>
										):(
											<React.Fragment>
												<Loader active inline="centered"/>
											</React.Fragment>
										)}
									</ul>
								):(
									<div>Unable to get Pick up points. Please refresh the page</div>
								)}

							</div>

						</li>
						<li className={styles.listItem}>
							<strong>Confirmation:</strong> We will get in touch to confirm the order.
							You may also enter the authorization code at the <Link to="/confirm/">confirmation page</Link>.
							The NegosyoNow authorization code for the order will be sent via SMS.
						</li>
						<li className={styles.listItem}>
							<strong>Processing:</strong> Once confirmed, the order will be forwarded to the pick up point for processing.
							They will coordinate with you regarding the availability of items, final amount and schedule of pickup.
						</li>
						<li className={styles.listItem}>
							<strong>Holding Period:</strong> A representative from the pick up point will inform you when the order is ready for purchase.
							The items will be reserved for 7 days from the date of notification.
							Unclaimed transactions will be cancelled after the holding period.
							You may opt to order again if necessary.
						</li>
						<li className={styles.listItem}>
							<strong>Payment</strong> will be made upon pick up.
						</li>
						<span id="third-party" className={styles.anchor}/>
						<li className={styles.listItem}>
							<strong>Customer-Booked Pick Up:</strong> You may opt to use third-party services, such as Grab, Lalamove, etc, to pick up your goods instead of claiming them personally.
							This can be indicated in your order so our team will know who will be coming for the items.
							It will be the sole responsibility of the customer to book, pay and coordinate with these services.
						</li>
						<li className={styles.listItem}>
							<strong>Completion:</strong> The pick up transaction will be considered completed once the order gets picked up.
						</li>
					</ol>
					<span id="bus-shipping" className={styles.anchor}/>
					<Header className={styles.header}>Bus Shipping</Header>
					<ol className={styles.text}>
						<li className={styles.listItem}>
							<strong>Check out:</strong> You can choose to pick up your items from a bus terminal at your location.
							Please indicate the service and terminal name so we can check if we will able to ship to that station.
						</li>
						<li className={styles.listItem}>
							<strong>Confirmation:</strong> We will get in touch to confirm the order.
							Our representative will explain the procedure and requirements to proceed.
						</li>
						<li className={styles.listItem}>
							<strong>Quotation:</strong> Our team will check the availability of the items and coordinate with the shipper to put together a quotation.
							The total amount due and bank account details will be provided.
						</li>
						<li className={styles.listItem}>
							<strong>Payment:</strong> Proof of payment will be required for us to proceed.
							Items will be prepared and shipped once the amount has cleared.
						</li>
						<li className={styles.listItem}>
							<strong>Shipment:</strong> Transaction will be considered complete upon turnover of the items to the courier.
						</li>
					</ol>
				</Container>
			</Layout>
		)
	}
}

